import {
  LAYOUT_MOBILE_BEFORE,
  LAYOUT_TABLET_BEFORE,
  LAYOUT_MOBILE_ADJUSTED,
  LOAD_LATEST_LEXICON_COOKIE_NAME,
  REFERRAL_COOKIE,
  COMPONENT_CONTEXT_INVENTORY,
  CONTEXT_CONFIGURATOR,
  VERIFY_IDME_COOKIE,
  VERIFY_UBER_COOKIE,
  VEFIFY_PROVIDER_MAP,
} from 'dictionary';
import Cookie from 'js-cookie';
import _get from 'lodash/get';

/**
 * Opens share panel
 * (only works in Android Chrome)
 * @param  {String} options.url   [description]
 * @param  {String} options.title [description]
 */
export function openSharePanel({ url, title }) {
  if (navigator.share) {
    navigator.share({
      title,
      url,
    });
  }
}

export function isIOS() {
  return navigator.userAgent.match(/ipad|iphone/i);
}

/**
 * Copies text to clipboard
 * @param  {String} str
 * @return {Boolean}
 */
export function copyToClipboard(str, inputEl) {
  //  works in Chrome & Opera
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    // works in Firefox, Safari, IE, etc...
    try {
      if (!inputEl) {
        return;
      }
      const input = inputEl;
      input.value = str;

      // iOS code found here https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
      if (isIOS()) {
        const range = document.createRange();
        range.selectNodeContents(input);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
      } else {
        input.select();
      }

      document.execCommand('copy');
    } catch {
      // noop
    }
  }

  openSharePanel({ url: str, title: window.title });
}

export function isWeChatBrowser() {
  return /MicroMessenger/i.test(navigator.userAgent.toLowerCase());
}

export function isWeChatMini() {
  return (
    /miniProgram/i.test(navigator.userAgent.toLowerCase()) ||
    window.location.search.indexOf('fromapp=minipro') !== -1
  );
}

/**
 * Get layout types (mobile, tablet)
 * @returns {object}
 */
export function getLayoutTypes() {
  const width = document.body.clientWidth;
  let layout = {
    isLayoutMobile: false,
    isLayoutTablet: false,
    isLayoutMobileAdjusted: false,
  };

  if (width <= LAYOUT_MOBILE_BEFORE) {
    layout = Object.assign(layout, {
      isLayoutMobile: true,
    });
  }

  if (width <= LAYOUT_MOBILE_ADJUSTED) {
    layout = Object.assign(layout, {
      isLayoutMobileAdjusted: true,
    });
  }

  if (width > LAYOUT_MOBILE_BEFORE && width <= LAYOUT_TABLET_BEFORE) {
    layout = Object.assign(layout, {
      isLayoutTablet: true,
    });
  }

  return layout;
}

export function getCookieInfoOfLatestLexiconLoading() {
  const cookieKey = _get(window.tesla, LOAD_LATEST_LEXICON_COOKIE_NAME);
  if (!cookieKey) {
    return [];
  }
  const cookieValue = Cookie.get(cookieKey) || '';
  return cookieValue ? cookieValue.split('/') : [];
}

export function getReferralCookie() {
  return Cookie.get(REFERRAL_COOKIE) || '';
}

export function getVerifyCookies() {
  return [VERIFY_IDME_COOKIE, VERIFY_UBER_COOKIE].reduce((acc, i) => {
    if (Cookie.get(i) === 'Y') {
      acc.push(VEFIFY_PROVIDER_MAP[i]);
    }
    return acc;
  }, []);
}

export const getPreQualCookie = () => {
  const result = Cookie.get('prequal');
  return result ? JSON.parse(result) : null;
};

export const getCookieConsent = () => {
  const result = Cookie.get('tsla-cookie-consent');
  return !!(result === 'accepted');
}

export const isCookieConsentLightRejected = () => {
  const result = Cookie.get('tsla-us-cookie-consent');
  return !!(result === 'rejected');
}

export const getLoggedInCookie = () => {
  const isInventory = !!(window?.tesla?.product?.data?.VIN || false);
  const appName = isInventory ? COMPONENT_CONTEXT_INVENTORY : CONTEXT_CONFIGURATOR
  return `tesla_logged_in_${appName}`;
}

export const isUserLoggedIn = () => {
  const cookie = getLoggedInCookie();
  const result = Cookie.get(cookie);
  return !!(result === 'Y');
}

export const resetLoggedIn = () => {
  if (isUserLoggedIn()) {
    const cookie = getLoggedInCookie();
    Cookie.remove(cookie);
  }
  return;
}